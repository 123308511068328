


<template>
  <BDCard title="场地实际使用率">
    <template #headRight>
      <div class="header-right">
        <MyDatePicker v-model="form.time" ref="MyDatePicker" @change="getMemberRevenue" />
      </div>
    </template>
    <div class="tips" v-if="mrData.use_compare" v-loading="loading">
      <div class="tip-box flex">
        <div class="left">
          <div class="info">
            <div class="value">{{ mrData.use_compare.total }}<span style=" font-size: 14px;">%</span></div>
          </div>
        </div>
        <div class="rigth">
          <div class="label">环比</div>
          <div class="value"> <span v-if="mrData.use_compare.status" :style="{
            color:
              mrData.use_compare.status == 1
                ? '#FE9595'
                : '#13CE66',
          }">{{ mrData.use_compare.diff_count }}%</span>
            <!-- 无变化  0 -->
            <span v-else>{{(mrData.use_compare.status == 1?'+':'-')+ mrData.use_compare.diff_count }}%</span>
            <img v-if="mrData.use_compare.status === 2" class="down-icon" src="../../assets/images/down.png" /><img
              v-else-if="mrData.use_compare.status === 1" class="up-icon" src="../../assets/images/up.png" />
          </div>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="content">
      <div class="left " v-if="mrData.use_compare">
        <div class="compare-box">
          <div class="img" style="background: rgba(255, 203, 44, .1);">
            <img src="../../assets/images/apply_compare.png" alt="">
          </div>
          <div class="info">
            <div class="title">申请次数</div>
            <div class="value">{{ mrData.apply_compare.total }}<span style=" font-size: 14px;">次</span></div>
          </div>
        </div>
        <div class="compare-box">
          <div class="img" style="background: rgba(255, 203, 44, .1);">
            <img src="../../assets/images/cancle_compare.png" alt="">
          </div>

          <div class="info">
            <div class="title">驳回次数</div>
            <div class="value">{{ mrData.cancel_compare.total }}<span style=" font-size: 14px;">次</span></div>
          </div>
        </div>
      </div>
      <div class="rigth">
        <div class="chart-box">
          <div class="chart-content" ref="chartContent1">
          </div>
          <div class="text" v-if="mrData.use_compare">
            <div>
              <div class="title">{{ mrData.check_compare.total }}%</div>
            </div>
            <div>
              申请通过率
            </div>
            <div class="value"> <span v-if="mrData.check_compare.status" :style="{
              color:
                mrData.check_compare.status == 1
                  ? '#FE9595'
                  : '#13CE66',
            }">{{(mrData.check_compare.status == 1?'+':'-')+  mrData.check_compare.diff_count }}%</span>
              <!-- 无变化  0 -->
              <span v-else>{{mrData.check_compare.diff_count }}%</span>
              <img v-if="mrData.check_compare.status === 2" class="down-icon" src="../../assets/images/down.png" /><img
                v-else-if="mrData.check_compare.status === 1" class="up-icon" src="../../assets/images/up.png" />
            </div>
          </div>
        </div>

        <div class="chart-box">
          <div class="chart-content" ref="chartContent2">
          </div>
          <div class="text" v-if="mrData.use_compare">
            <div>
              <div class="title">{{ mrData.uncheck_compare.total }}%</div>
            </div>
            <div>
              不申请通过率
            </div>
            <div class="value"> <span v-if="mrData.use_compare.status" :style="{
              color:
                mrData.uncheck_compare.status == 1
                  ? '#FE9595'
                  : '#13CE66',
            }">{{ (mrData.uncheck_compare.status == 1?'+':'-')+ mrData.uncheck_compare.diff_count }}%</span>
              <!-- 无变化  0 -->
              <span v-else>{{ mrData.uncheck_compare.diff_count }}%</span>
              <img v-if="mrData.uncheck_compare.status === 2" class="down-icon" src="../../assets/images/down.png" /><img
                v-else-if="mrData.uncheck_compare.status === 1" class="up-icon" src="../../assets/images/up.png" />
            </div>
          </div>
        </div>
      </div>

    </div>


  </BDCard>
</template>

<script>
import BDCard from "@/modules/organization-admin/components/DataStats/BDCard.vue";
import MyDatePicker from "./MyDatePicker.vue";
import { getReserveStats } from '../../api/venue-detail-statistics'
import MyPieChart from "../../assets/echarts/pie-chart";

export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        time: [-1, -1],
        compare_type: 4
      },
      mrData: {}, //获取的数据
      //图表数据
      chartData1: [],
      //x轴坐标数据
    };
  },
  methods: {
    //获取统计数据
    getMemberRevenue() {
      this.loading = true;
      this.init = false;
      const postData = this.getFormData();
      getReserveStats({ ...postData, id: this.$route.params.id }).then((res) => {
        const { data } = res;
        console.log(data, 54);
        this.mrData = data;

        this.transformData();
        this.initChart();
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form, };
      const [start_time = "", end_time = ""] = data.time;
      Object.assign(data, { start_time, end_time });
      Reflect.deleteProperty(data, "time");
      return data;
    },
    //转换数据格式
    transformData() {
      this.chartData1 = [{ name: "通过", value: this.mrData.check_compare.total, itemStyle: { color: '#4EBB0C' } }, { value: 100 - this.mrData.check_compare.total, itemStyle: { color: '#EDF8E7' } }]
      this.chartData2 = [{ name: "不通过", value: this.mrData.uncheck_compare.total, itemStyle: { color: '#FFCB2C' } }, { value: 100 - this.mrData.uncheck_compare.total, itemStyle: { color: '#FFFAEA' } },]
    },
    //初始化图表
    initChart() {
      if (!this.$chart1) {
        try {
          this.$chart1 = new MyPieChart({
            container: this.$refs.chartContent1,
            data: this.chartData1,
            legend: { show: false },
            series: {
              radius: ["60%", "70%"],
            },
          })
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setChartOption();
      }
      if (!this.$chart2) {
        try {
          this.$chart2 = new MyPieChart({
            container: this.$refs.chartContent2,
            data: this.chartData2,
            legend: { show: false },
            series: {
              radius: ["60%", "70%"],
            },
          })
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setChartOption();
      }
    },
    //设置图表
    setChartOption() {
      this.$chart1.setOption(this.chartData1);
      this.$chart2.setOption(this.chartData2)
    },
    //销毁实例
    destroyChart() {
      if (this.$chart1) {
        this.$chart1.dispose();
      }
      if (this.$chart2) {
        this.$chart2.dispose();
      }
    },
    // 图标统计数据导出
  },
  created() {
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs["MyDatePicker"].updateTime();
      this.getMemberRevenue();
    });
  },
  components: {
    BDCard,
    MyDatePicker,
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 300px;
  display: flex;
  align-items: center;

  .rigth {
    display: flex;

    .chart-box {
      position: relative;

      .text {
        position: absolute;
        left: 50%;
        top: 45%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;

        align-items: center;

        .title {

          font-size: 30px;
          color: #303133;
          font-weight: 700;

        }

        .value {
          span {
            color: #f1c56f;
            font-size: 1.28em;
            padding: 0 0.66em;
          }

          .up-icon {
            width: 0.85em;
            height: 0.85em;
            animation: 0.7s up infinite;
          }

          .down-icon {
            width: 0.85em;
            height: 0.85em;
            animation: 0.7s down infinite;
          }

        }
      }

    }

    .chart-content {
      margin-top: 20px;
      width: 250px;
      height: 280px;
    }

  }

  .left {
    width: 180px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .compare-box {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 29px;

      .img {
        width: 48px;
        height: 48px;
        margin-right: 10px;

        img {
          width: 48px;
          height: 48px;
        }
      }
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 40px;

      .title {
        color: #909399;
        font-size: 13px;
      }

      .value {
        font-size: 18px;
        color: #303133;
        font-weight: 700;
      }
    }
  }
}

.header-right {
  display: flex;
  align-items: center;

  .primary-plain {
    margin-left: 20px;
  }
}

.tips {
  display: flex;
  margin-bottom: 13px;

  .tip-box {
    width: 50%;
    height: 48px;

    .left {

      border-right: 1px solid #E4E7ED;
      display: flex;
      padding-right: 20px;


      .img {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        img {
          width: 28px;
          height: 28px;

        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          color: #909399;
          font-size: 13px;
        }

        .value {
          font-size: 40px;
          color: #303133;
          font-weight: 700;
        }
      }
    }

    .rigth {

      margin-left: 20px;
      display: flex;
      align-items: center;
      font-size: 13px;

      .label {
        font-weight: 500;
        color: #303133;
        display: flex;
        align-items: center;


      }

      .value {
        span {
          color: #f1c56f;
          font-size: 1.28em;
          padding: 0 0.66em;
        }

        .up-icon {
          width: 0.85em;
          height: 0.85em;
          animation: 0.7s up infinite;
        }

        .down-icon {
          width: 0.85em;
          height: 0.85em;
          animation: 0.7s down infinite;
        }
      }
    }
  }
}
</style>
