<template>
  <div class="c-weektime">

    <div class="c-schedue"></div>
    <div
      :class="{ 'c-schedue': true, 'c-schedue-notransi': mode }"
      :style="styleValue"
    ></div>

    <div class="c-weektime-table-wrapper">
      <table border="0" cellpadding="0" cellspacing="0" :class="{ 'c-min-table': colspan < 2 }" class="c-weektime-table">
        <thead class="c-weektime-head">
          <tr>
            <th v-if="data.length > 1" rowspan="8" class="week-td">日期/时间</th>
            <th :colspan="12 * colspan * 0.5">
              <i class="el-icon-time"> </i> 06:00 - 12:00
            </th>
            <th :colspan="12 * colspan">
              <i class="el-icon-time"> </i> 12:00 - 24:00
            </th>
          </tr>
          <tr>
            <td v-for="t in theadArr" :key="t" :colspan="colspan">{{ t }}</td>
          </tr>
        </thead>
        <tbody class="c-weektime-body">
          <tr v-for="t in data" :key="t.row">
            <td v-if="data.length > 1">{{ t.value }}</td>
            <td
              v-for="n in t.child"
              :key="`${n.row}-${n.col}`"
              :data-week="n.row"
              :data-time="n.col"
              :class="selectClasses(n)"
              class="weektime-atom-item"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const createArr = (len) => {
  return Array.from(Array(len)).map((ret, id) => id + 6)
}
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    colspan: {
      type: Number,
      default() {
        return 2
      },
    },
  },
  data() {
    return {
      width: 0,
      height: 0,
      left: 0,
      top: 0,
      mode: 0,
      row: 0,
      col: 0,
      theadArr: [],
      border: 1
    }
  },
  computed: {
    styleValue() {
      return {
        // - (this.col - 1 > 0 ? this.col - 1 : 0) * 10
        //  - (this.row - 1 > 0 ? this.row - 1 : 0) * 10
        width: `${this.width + this.border}px`,
        height: `${this.height + this.border}px`,
        //  + 1.5 * this.border
        //  + 1.5 * this.border
        left: `${this.left + 1}px`,
        top: `${this.top + 1}px`,
      }
    },


    selectClasses() {
      return (n) => (n.check? 'ui-selected' : '')
    },
  },
  created() {
    this.theadArr = createArr(24-6)
    console.log(this.theadArr,'this.theadArr');
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
$border-color: #dcdfe6;

.c-weektime {
  min-width: 640px;
  width: 100%;
  position: relative;
  display: inline-block;
}

.c-weektime-table-wrapper {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid $border-color;
  box-sizing: content-box;
}

.c-schedue {
  background: $primary;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0.6;
  pointer-events: none;
}
.c-schedue-notransi {
  transition: width 0.12s ease, height 0.12s ease, top 0.12s ease,
    left 0.12s ease;
}
.c-weektime-table {
  border-collapse: collapse;
  width: 100%;
  min-width: max-content;

  thead tr {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    th {
      line-height: 42px;
      color: #303133;
    }
  }

  tbody tr:last-child td {
    border-bottom: 1px solid transparent;
  }

  tbody td:first-child {
    color: #606266;
  }

  th {
    vertical-align: inherit;
    font-weight: bold;
  }
  tr {
    height: 30px;

    th:first-child,
    td:first-child {
      border-left: 1px solid transparent;
    }

    &:first-child th {
      border-top: 1px solid transparent;
    }

    &:last-child td.weektime-atom-item {
    }

    th:last-child,
    td:last-child {
      border-right: 1px solid transparent;
    }
  }
  tr,
  td,
  th {
    user-select: none;
    border: 1px solid $border-color;
    text-align: center;
    width: 20px;
    /*line-height: 1.8em;*/
    line-height: normal;
    transition: background 0.2s ease;
    box-sizing: content-box;
    height: 24px;
  }

  .c-weektime-head {
    font-size: 12px;
    .week-td {
      width: 70px;
    }
  }
  .c-weektime-body {
    font-size: 12px;
    td {
      &.weektime-atom-item {
        user-select: unset;
        /*background-color: #f5f5f5;*/
      }
      &:not(.weektime-atom-item) {
        width: 64px;
      }
      &.ui-selected {
        background-color: $primary;
      }
    }
  }
  .c-weektime-preview {
    line-height: 2.4em;
    padding: 0 10px;
    font-size: 14px;
    .c-weektime-con {
      line-height: 46px;
      user-select: none;
    }
    .c-weektime-time {
      text-align: left;
      line-height: 2.4em;
      p {
        max-width: 625px;
        line-height: 1.4em;
        word-break: break-all;
        margin-bottom: 8px;
      }
    }
  }
}
.c-min-table {
  tr,
  td,
  th {
    min-width: 24px;
  }
}
.g-clearfix {
  &:after,
  &:before {
    clear: both;
    content: ' ';
    display: table;
  }
}
.g-pull-left {
  float: left;
  color: #606266;
}
.g-pull-right {
  float: right;
  color: #c0c4cc;
}
.g-tip-text {
  color: #999;
}

.preview-time {
  min-height: 48px;
  border-radius: 4px;
  background: #f5f7fa;
  padding: 13px 16px;
  line-height: normal;
  align-items: flex-start;

  .el-button {
    padding: 0.2em;
  }

  p {
    color: #606266;
    font-size: 14px;
  }

  & + .preview-time {
    margin-top: 8px;
  }
}

.danger {
  color: $danger;
}
</style>
