

<template>
  <BDCard title="最近7天场地使用时间段统计">
    <MyDatePicker v-show="false" v-model="form.time" ref="MyDatePicker" @change="getMemberRevenue"></MyDatePicker>
    <DragWeektime  v-loading="loading"   :data="mrData.list" />
  </BDCard>
</template>

<script>
import BDCard from "@/modules/organization-admin/components/DataStats/BDCard.vue";
import MyDatePicker from "./MyDatePicker.vue";
import { getReserveTimeRecent } from '../../api/venue-detail-statistics.js'
import DragWeektime from './DragWeektime.vue'
import weektimeData from '../../data/weektime_data2.js'
import { parseToStamp, parseToTime } from '../../js/time-tool'
export default {
  // components:{asas ,MyDatePicker,BDCard},
  data() {
    return {
      loading: false, //加载中
      form: {
        time: [-1, -1],
      },
      mrData: {
        list: []
      }, //获取的数据
      arr: []
    };
  },
  methods: {
    //获取统计数据
    getMemberRevenue() {
      this.loading = true;
      this.init = false;
      let postData = this.getFormData()
      getReserveTimeRecent({ ...postData, id: this.$route.params.id })
        .then((res) => {
          const { data } = res
          this.mrData.list =  this.getList(data.list)
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getList(list) {
      const matcher = list.map(t=>t.day_time)
      const dragData = JSON.parse(JSON.stringify(weektimeData(matcher)))
      let list2 = []
      list.forEach((t,i)=>{
        t.times.forEach(t2=>{
          list2.push({day:i,...t2})
        })
 
      })
      dragData.forEach((wd) => {
        console.log(parseToStamp);
        list2.forEach((dt) => {

          if (dt.day === matcher.indexOf(wd.value)) {
            console.log(wd.child);
            wd.check = true
            wd.child.forEach((ch) => {
              // console.log([
              //   parseToStamp('07:00'),
              //   parseToStamp('07:30'),
              //   parseToStamp('08:00'),
              //   parseToStamp('12:00'),
              //   parseToStamp('24:00')
              // ])
              const td = parseToStamp(ch.begin)
              if (td >= dt.begin && td < dt.end) {
                ch.check = true
              }
            })
          }
        })
      })
      console.log(dragData,'dragData');
      return dragData
    },
    getFormData() {
      const data = { ...this.form, };
      const [start_time = "", end_time = ""] = data.time;
      Object.assign(data, { start_time, end_time });
      Reflect.deleteProperty(data, "time");
      return data;
    },
  },
  created() {
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs["MyDatePicker"].updateTime();
      this.getMemberRevenue();
    });
  },
  components: {
    BDCard,
    MyDatePicker,
    DragWeektime
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 300px;
}

.header-right {
  display: flex;
  align-items: center;

  .primary-plain {
    margin-left: 20px;
  }
}
</style>
