
<template>
    <div class="page-mains">
        <div>
               <ReserveCountStats />
        </div>
        <div>
          
            <TypeStats /> 
        </div>
        <div>
          <ReserveStats />
        </div>
        <div>
            <ReserveTimeStats />
        </div>
        <div style="grid-column-start: span 2; height: 350px;">
            <ReserveTimeRecent />
        </div>

    </div>
</template>

<script>
import ReserveTimeRecent from "../components/venue-detail-statistics/ReserveTimeRecent.vue"
import TypeStats from "../components/venue-detail-statistics/TypeStats.vue"
import ReserveTimeStats  from '../components/venue-detail-statistics/ReserveTimeStats.vue'
import ReserveCountStats from '../components/venue-detail-statistics/ReserveCountStats.vue'
import ReserveStats from'../components/venue-detail-statistics/ReserveStats.vue'
export default {
    components: { ReserveTimeRecent,TypeStats,ReserveTimeStats,ReserveCountStats,ReserveStats }
}
</script>

<style lang="scss" scoped>
.page-mains {
    background: #f5f6f7;
    
    // height: 400px;
    padding: 20px;
    display: grid;
    // overflow: hidden;
    // width: 70%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, );
    gap: 20px;
    >div{
        width: 100%;
    }
}
</style>