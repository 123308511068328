export function parseToStamp(str) {
  // str likes "07:00"
  const strArr = str
    .trim()
    .split(':')
    .map((s) => s.trim())
  const dateZero = new Date(1970, 0, 1, 0, 0, 0)
  const hour = strArr[0]

  const minute = strArr[1]
  const dateStr = new Date(1970, 0, 1, hour, minute, 0)

  return dateStr.getTime() / 1000 - dateZero.getTime() / 1000
}

export function parseToTime(stamp) {
  const hour = stamp / 60 / 60
  const remained = stamp % 3600
  return `${_formatZero(Math.floor(hour))}:${remained > 0 ? '30' : '00'}`
}

function _formatZero(n) {
  if (n < 10) {
    return `0${n}`
  }
  return n.toString()
}
