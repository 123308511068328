

<template>
  <BDCard title="场地使用类型统计">
    <template #headRight>
      <div class="header-right">
        <MyDatePicker v-model="form.time" ref="MyDatePicker" @change="getMemberRevenue" />
      </div>
    </template>
    <div class="tips" v-if="mrData.activity_compare">
      <div class="tip-box flex">
        <div class="left">
          <div class="img" style="background: rgba(255, 203, 44, .1);">
            <img src="../../assets/images/activity_compare.png" alt="">
          </div>

          <div class="info">
            <div class="title">活动场数</div>
            <div class="value">{{ mrData.activity_compare.total }}<span style=" font-size: 14px;">场</span></div>
          </div>
        </div>
        <div class="rigth">
          <div class="label">环比</div>
          <div class="value"> <span v-if="mrData.activity_compare.status" :style="{
            color:
              mrData.activity_compare.status == 1
                ? '#FE9595'
                : '#13CE66',
          }">{{(mrData.activity_compare.status == 1?'+':'-')+ mrData.activity_compare.diff_count }}%</span>
            <!-- 无变化  0 -->
            <span v-else>{{ mrData.activity_compare.diff_count }}%</span>
            <img v-if="mrData.activity_compare.status === 2" class="down-icon" src="../../assets/images/down.png" /><img
              v-else-if="mrData.activity_compare.status === 1" class="up-icon" src="../../assets/images/up.png" />
          </div>
        </div>
      </div>
      <div class="tip-box flex">
        <div class="left">
          <div class="img" style="background: rgba(30, 195, 248, .1);">
            <img src="../../assets/images/meeting_compare.png" alt="">
          </div>

          <div class="info">
            <div class="title">会议场数</div>
            <div class="value">{{ mrData.meeting_compare.total }}<span style=" font-size: 14px;">场</span></div>
          </div>
        </div>
        <div class="rigth">
          <div class="label">环比</div>
          <div class="value"> <span v-if="mrData.meeting_compare.status" :style="{
            color:
              mrData.meeting_compare.status == 1
                ? '#FE9595'
                : '#13CE66',
          }">{{(mrData.meeting_compare.status == 1?'+':'-')+ mrData.meeting_compare.diff_count }}%</span>
            <!-- 无变化  0 -->
            <span v-else>{{ mrData.meeting_compare.diff_count }}%</span>
            <img v-if="mrData.meeting_compare.status === 2" class="down-icon" src="../../assets/images/down.png" /><img
              v-else-if="mrData.meeting_compare.status === 1" class="up-icon" src="../../assets/images/up.png" />
          </div>
        </div>
      </div>

    </div>
    <div class="content" ref="chartContent" v-loading="loading">
    </div>
  </BDCard>
</template>

<script>
import BDCard from "@/modules/organization-admin/components/DataStats/BDCard.vue";
import MyDatePicker from "./MyDatePicker.vue";
import { getTypeStats } from '../../api/venue-detail-statistics'
import MylineChart from "@/modules/venue/assets/echarts/line-chart";
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        time: [-1, -1],
        compare_type: 4
      },
      mrData: {}, //获取的数据
      //图表数据
      chartData: [],
      //x轴坐标数据
      xData: [],
      // 柱状图所在tab标识
      tabPage: "usageStats",
      extraData: {
        // 总人数数组数据
        totals: [],
        // 小程序使用人数数组数据
        mp_use_totals: [],
        // h5使用人数数组数据
        h5_use_totals: [],
      },
    };
  },
  methods: {
    //获取统计数据
    getMemberRevenue() {
      this.loading = true;
      this.init = false;
      const postData = this.getFormData();
      getTypeStats({ ...postData, id: this.$route.params.id })
        .then((res) => {
          const { data } = res;
          this.mrData = data;

          this.transformData();
          this.initChart();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form, };
      const [start_time = "", end_time = ""] = data.time;
      Object.assign(data, { start_time, end_time });
      Reflect.deleteProperty(data, "time");
      return data;
    },
    //转换数据格式
    transformData() {
      const meeting = this.mrData.list.meeting.map((item) => {
        return {
          value: item.count
        };
      });
      const activity = this.mrData.list.activity.map((item) => {
        return {
          value: item.count
        };
      });
      this.chartData = Object.assign({}, { activity, meeting });
      console.log(this.chartData, "this.chartData");
      this.xData = this.mrData.list.meeting.map((item) => item.day_time);
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        try {
          this.$chart = new MylineChart({
            container: this.$refs.chartContent,
            data: this.chartData,
            xData: this.xData,
            tabPage: this.tabPage,
            extraData: this.extraData,
            legend: {
              right: 0,
              top: 0,
              icon: "rect",
              data: ["活动", "会议"],
            },
            yAxis: {
              type: "value",
              name: "单位：场",
            },
            grid: {
              left: "20px",
              right: "20px",
              bottom: "50px",
              top: "40px",
              containLabel: true,
            },
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        this.setChartOption();
      }
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.xData, this.chartData);
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose();
      }
    },
    // 图标统计数据导出
  },
  created() {
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs["MyDatePicker"].updateTime();
      this.getMemberRevenue();
    });
  },
  components: {
    BDCard,
    MyDatePicker,
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 300px;
}

.header-right {
  display: flex;
  align-items: center;

  .primary-plain {
    margin-left: 20px;
  }
}

.tips {
  display: flex;
  margin-bottom: 13px;

  .tip-box {
    width: 50%;
    height: 48px;

    .left {
      border-right: 1px solid #E4E7ED;
      display: flex;
      padding-right: 20px;

      .img {
        width: 48px;
        height: 48px;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;

        img {
          width: 28px;
          height: 28px;

        }
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          color: #909399;
          font-size: 13px;
        }

        .value {
          font-size: 18px;
          color: #303133;
          font-weight: 700;
        }
      }
    }

    .rigth {
      justify-content: center;
      display: flex;
      align-items: center;
      margin-left: 20px;
      font-size: 13px;

      .label {
        font-weight: 500;
        color: #303133;
        display: flex;
        align-items: center;


      }

      .value {
        span {
          color: #f1c56f;
          font-size: 1.28em;
          padding: 0 0.66em;
        }

        .up-icon {
          width: 0.85em;
          height: 0.85em;
          animation: 0.7s up infinite;
        }

        .down-icon {
          width: 0.85em;
          height: 0.85em;
          animation: 0.7s down infinite;
        }
      }
    }
  }
}</style>
