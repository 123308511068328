import api from '@/base/utils/request'

export const getReserveStats = (data) => {
  return api({
    url: '/admin/venue/venueStats/reserveStats',
    method: 'post',
    data
  })
}
export const getTypeStats = (data) => {
    return api({
      url: '/admin/venue/venueStats/typeStats',
      method: 'post',
      data
    })
  }
  export const getReserveTimeStats = (data) => {
    return api({
      url: '/admin/venue/venueStats/reserveTimeStats',
      method: 'post',
      data
    })
  }
  
  export const getReserveCountStats = (data) => {
    return api({
      url: '/admin/venue/venueStats/reserveCountStats',
      method: 'post',
      data
    })
  }
  export const getReserveTimeRecent = (data) => {
    return api({
      url: '/admin/venue/venueStats/reserveTimeRecent',
      method: 'post',
      data
    })
  }
  
  
